import styled from 'styled-components'

const TitleDefault = styled.h1`
  font-size: ${(props) => props.theme.font.size['65']};
  font-weight: ${(props) => props.theme.font.weight.l};
  font-family: ${(props) => props.theme.font.family.main};
  text-transform: uppercase;
  @media (max-width: 575px) {
    font-size: ${(props) => props.theme.font.size.xxl};
  }
`

export default TitleDefault
